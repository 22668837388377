.clickable:hover, .clickable:focus {
    background-color: var(--list-group-hover-bg-color, --primary-color);
}

.collection {
    overflow: auto;
    .modal-body {
    max-height: calc(100vh - 235px);
    min-height: 150px;
        .list-group {
            overflow: auto;
            max-height: calc(100vh - 355px);
            min-height: 32px;
        }
    }
}
